import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { Notifications as Toasts } from "@mantine/notifications";
import { NavigationProgress, nprogress } from "@mantine/nprogress";
import { json, LoaderFunctionArgs } from "@remix-run/node";
import {
  ClientLoaderFunctionArgs,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigation,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Provider as JotaiProvider } from "jotai";
import { useEffect } from "react";
import { cacheClientLoader, useCachedLoaderData } from "remix-client-cache";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/nprogress/styles.css";
import "@xyflow/react/dist/style.css";

import { useToast } from "~/components/toast";
import { theme } from "~/lib/mantine-theme";
import { getToast } from "~/lib/toast.server";
import { combineHeaders } from "~/lib/utils";

import "leaflet/dist/leaflet.css";
import "~/styles/tailwind.css";
import ModalConfirm from "./components/modal/modal-confirm";
import ModalConfirmation from "./components/modal/modal-confirmation";
import ModalDeleteGlobal from "./components/modal/modal-delete-global";
import { GetProfileQuery, Permission } from "./graphql/__generated__/types";
import { getProfile } from "./graphql/queries/profile";
import AbilityGuard from "./lib/ability/ability-guard";
import { requireAuth } from "./lib/auth";
import { graphQLFetch } from "./lib/graphql-fetcher";
import { getSession } from "./session.server";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const accessToken = await requireAuth(request);

  const { toast, headers: toastHeaders } = await getToast(request);
  const session = await getSession(request);
  let permissions: Permission[] = [];
  if (!accessToken) {
    return json(
      {
        toast,
        permissions,
        notificationCount: 0,
      },
      {
        headers: combineHeaders(toastHeaders),
      },
    );
  }

  const url = new URL(request.url);
  if (url.pathname.includes("dashboard")) {
    const response = await graphQLFetch<GetProfileQuery>({
      query: getProfile,
      request,
      session,
    });

    if (response.data?.me.peran?.permises) {
      permissions = response.data?.me.peran?.permises as Permission[];
    }
  }

  return json(
    {
      toast,
      permissions,
    },
    {
      headers: combineHeaders(toastHeaders),
    },
  );
};

export const clientLoader = (args: ClientLoaderFunctionArgs) =>
  cacheClientLoader(args, {
    key: "user-profile",
  });

clientLoader.hydrate = true;

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const { toast: toastData, permissions } =
    useCachedLoaderData<typeof loader>();

  useToast(toastData);

  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state === "loading") {
      nprogress.start();
    } else {
      nprogress.complete();
    }
  }, [navigation.state]);

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/favicon-16x16.png`}
        />
        <link rel="manifest" href="/site.webmanifest" />
        <ColorSchemeScript />
      </head>
      <body className="h-full bg-[#F8F9FA]">
        <MantineProvider theme={theme}>
          <NavigationProgress />
          <JotaiProvider>
            <ModalConfirmation />
            <ModalDeleteGlobal />
            <ModalConfirm />
            <AbilityGuard permissions={permissions}>
              <Outlet />
              <ScrollRestoration />
              <Scripts />
              <Toasts />
            </AbilityGuard>
          </JotaiProvider>
        </MantineProvider>
      </body>
    </html>
  );
}
